import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'

const Pearson = () => (
  <Layout>
    <h1>Pearson Accelerated Pathways</h1>
    <article>
      <p>In early 2020, after the acquisition of Lumerit Education, Pearson launched a brand new B2C product line, Accelerated Pathways. The international education company had little experience marketing directly to consumers and needed to turn around new brand material in record time in order to support the growth of their recent acquisition. It was my job to utilize Pearson’s established brand guidelines in a way that would appeal to both the business and consumer markets.</p>
      <p>The basis of this brand transition was their website, <a href='https://pearsonaccelerated.com' target='_blank'>pearsonaccelerated.com</a>. Accelerated Pathways’ mission is to offer education without barriers. I wanted the website to reflect this in every aspect, so I chose a clean, interactive, and visually compelling design which naturally embodied the company's brand message.</p>
      <img src='/home-slice.png'/>
      <p>This meant re-designing the flow of the site’s top tier pages. These pages were out of date already, built on a no-longer supported Rails infrastructure, and the content failed to provide proper context for the current product offering. In addition, part of this branding transition required consolidating several sub-brands into one cohesive unit. This meant planning and overseeing the implementation of a new site structure in order to make the content both easily digestible by the user and well-organized for future development.</p>
      <img src='/college-planning-slice.png'/>
      <p>I worked closely with the marketing content team to develop a new information architecture for the website featuring a clean, user-friendly design. The goal was to present complex information in a way that was clear yet comprehensive. I chose to transition the site from its Rails infrastructure into a React-based site which greatly improved the overall performance and accessibility of each page and enabled me to employ an accordion layout, featuring imagery designed to draw the eye throughout each page. This way, users could easily skim content for an overview of the topic and dig in for further understanding on the specific points that captured their interest.</p>
      <p>Overall, these updates successfully decreased bounce rate across the site by 22%.</p>
      <p><a href='https://pearsonaccelerated.com' target='_blank'>View live site</a></p>
    </article>
  </Layout>
)

export default Pearson
